import { MantineProvider } from "@mantine/core";
import { GlassDrop } from "./GlassDrop";

export default function App() {
	return (
		<MantineProvider withGlobalStyles withNormalizeCSS>
			<GlassDrop />
		</MantineProvider>
	);
}
