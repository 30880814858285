import { Image, Text, Badge, UnstyledButton, Stack, Paper, Button } from "@mantine/core";
import { IconTrash } from "@tabler/icons-react";
import React from "react";
import { useLocation } from "react-router-dom";
import { Action, GlassDoc } from "../Reducer";
import { checkLinkExpiration, deleteFile } from "../utils/helpers";

export function FileComponent({ file, dispatch }: { file: GlassDoc; dispatch: React.Dispatch<Action> }) {
	const location = useLocation().pathname;

	/**
	 * Redirects the browser to the RawData version of the file.
	 *
	 * @returns {Promise<void>}
	 */
	async function openFile() {
		if (file.presignedUrl === null) return;
		let url = file.presignedUrl;

		const linkExpired = checkLinkExpiration(file);

		if (linkExpired) {
			// refetch();
		} else {
			window.open(url, "_blank");
		}
	}

	return (
		<>
			<Stack align={"center"}>
				<UnstyledButton onClick={openFile}>
					<Image style={{ width: "55px", height: "55px" }} src={"data:image/gif;base64, " + file.icon} />
				</UnstyledButton>
				<Paper shadow="xl" radius="xs" p="md" withBorder>
					<Badge color="blue" variant="light">
						{decodeURI(file.fileName)}
					</Badge>
					<Text>Bucket: {file.bucket}</Text>
					<Text>FileKey: {decodeURI(file.fileKey)}</Text>
				</Paper>
				<Button color={"red"} leftIcon={<IconTrash />} uppercase onClick={() => deleteFile(file, location, dispatch)}>
					delete file
				</Button>
			</Stack>
		</>
	);
}
