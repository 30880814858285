import { FileWithPath } from "@mantine/dropzone";
import { Location } from "react-router-dom";
import { Action, ActionType, GlassDoc } from "../Reducer";
import { API_URL } from "../utils/consts";

/**
 * Checks if the file is a GlassDoc
 *
 * @param fileKey
 * @param bucket
 * @param data
 * @returns
 */
function isGlassDoc(fileKey: string, bucket: string, data: Response): GlassDoc {
	const contentType = data.headers.get("content-type");
	const fileName = data.headers.get("x-file-name");
	const icon = data.headers.get("x-icon-base64");
	const presignedUrl = data.headers.get("x-presigned-url");

	if (contentType === null || fileName === null || icon === null || presignedUrl === null) throw new Error("Missing header(s)");

	const doc: GlassDoc = {
		contentType,
		fileKey,
		fileName,
		icon,
		presignedUrl,
		bucket,
	};

	if (presignedUrl !== null) doc.presignedUrl = presignedUrl;

	return doc;
}

/**
 * Calls the API with a HEAD request to see if a file exists. Returns the metadata if it does.
 *
 * @param location
 * @param dispatch
 * @returns
 */
export async function callHeadObject(location: string, dispatch: React.Dispatch<Action>) {
	try {
		let locArry = location.split("/").slice(2); // Remove the first two elements of the array (the dataType and slash)

		// If the array is less than 3, then the user is on the wrong page.
		if (locArry.length < 3) {
			dispatch({ type: ActionType.WRONG_PAGE });
			return;
		}

		let bucket = locArry.shift();
		let filekey = locArry.join("/");

		// This would never happen but we have to make sure typescript is happy
		if (!bucket) {
			dispatch({ type: ActionType.WRONG_PAGE });
			return;
		}

		console.log("Calling API with: " + API_URL + "/" + bucket + "/" + filekey);
		const response = await fetch(API_URL + "/" + bucket + "/" + filekey, {
			method: "HEAD",
			headers: {
				"Content-Type": "application/json",
			},
		});

		if (response.status === 404) {
			dispatch({ type: ActionType.APIDONE, file: null });

			throw new Error("File not found");
		} else if (response.status !== 200) {
			console.log("Error: " + response.statusText);
			console.log(response);
			throw new Error(response.statusText);
		}

		// Shouldn't happen, but TS needs to be happy
		if (response.headers.get("content-type") === null) {
			dispatch({ type: ActionType.ERROR, error: "Content Type was missing from the file." });
			throw new Error("Content Type was missing from the file.");
		}

		// Remove trailing slash from fileKey
		if (filekey.endsWith("/")) filekey = filekey.slice(0, -1);

		const data = isGlassDoc(filekey, bucket, response); // confirm our object is a GlassDoc type for typescript

		dispatch({ type: ActionType.APIDONE, file: data });
	} catch (err) {
		const error = err as Error;
		console.log("🚀 ~ file: query.tsx:94 ~ callHeadObject ~ error", error);
		if (error.message === "File not found") return; // We don't want to throw an error here because we handle it with dispatch and state

		dispatch({ type: ActionType.ERROR, error: "ACCESS DENIED" });
	}
}

/**
 * Uploads a file to S3. Calls a POST to get the URL and a PUT to upload the file.
 *
 * @param file
 * @param location
 * @param dispatch
 */
export async function upload(file: FileWithPath, location: Location, dispatch: React.Dispatch<Action>) {
	try {
		dispatch({ type: ActionType.UPLOAD_STARTED });

		const path = location.pathname.split("/").slice(2);
		const bucket = path.shift();
		const fileKey = path.join("/");

		// This would never happen but we have to make sure typescript is happy
		if (!bucket) {
			dispatch({ type: ActionType.ERROR, error: "Please visit a valid URL" });
			throw new Error("Please visit a valid URL");
		}

		const postReq = await fetch(API_URL + location.pathname, {
			method: "POST",
			headers: {
				"Content-Type": file.type,
			},
			body: JSON.stringify({ filename: file.name, contentType: file.type }),
		});

		if (postReq.status !== 200) {
			dispatch({ type: ActionType.ERROR, error: "Fetch Error" });
			throw new Error("Fetch Error");
		}

		const presignedUrl = postReq.headers.get("x-presigned-url");

		// Could happen if we call with x-omit-url but we don't. Still need to typeguard
		if (presignedUrl === null) {
			dispatch({ type: ActionType.ERROR, error: "No URL found in response" });
			throw new Error("No URL found in response");
		}

		putObjectToS3(presignedUrl, fileKey, file, bucket, dispatch);
	} catch (err) {
		// We don't want to throw an error here because we handle it with dispatch and state
	}
}

interface IFileMaker {
	PerformScriptWithOption: any;
}

declare var FileMaker: IFileMaker;

/**
 * Uploads a file to S3 using a presigned URL
 *
 * @param url
 * @param fileKey
 * @param file
 * @param bucket
 * @param dispatch
 */
function putObjectToS3(url: string, fileKey: string, file: FileWithPath, bucket: string, dispatch: React.Dispatch<Action>) {
	let request = new XMLHttpRequest(); // We use XMLHttpRequest because fetch doesn't support progress events

	// This is the progress event to update the progress bar
	request.upload.onprogress = (event) => {
		if (event.lengthComputable) {
			const percent = Math.round((event.loaded / event.total) * 100);
			dispatch({ type: ActionType.UPLOAD_PERCENT, percent });
		}
	};

	request.open("PUT", url, true);

	request.setRequestHeader("Content-Type", file.type);

	// File upload complete, now we need to update the state
	request.onreadystatechange = function () {
		if (request.readyState === 4) {
			if (request.status === 200) {
				dispatch({
					type: ActionType.UPLOAD_COMPLETE,
				});
				try {
					console.log("attempting fm script");
					console.log("FileMaker", FileMaker);
					const fmData = {
						isChain: true,
					};
					FileMaker.PerformScriptWithOption("ReceiveJavaScriptResponse", JSON.stringify(fmData), "5");
				} catch (err) {
					console.log("🚀 ~ file: query.tsx:192 ~ putObjectToS3 ~ err:", err);
				}
				// call a function to post to FM here
			} else {
				dispatch({
					type: ActionType.ERROR,
					error: "Upload failed",
				});
			}
		}
	};

	request.send(file);
}
