import { Group, Progress, Stack, Text, useMantineTheme } from "@mantine/core";
import { Dropzone, IMAGE_MIME_TYPE, MIME_TYPES, PDF_MIME_TYPE } from "@mantine/dropzone";
import { IconPhoto, IconUpload, IconX } from "@tabler/icons-react";
import { Dispatch } from "react";
import { useLocation } from "react-router-dom";
import { Action, State } from "../Reducer";
import { upload } from "../query/query";

const MAX_SIZE_MB = 100;

interface GlassDropZoneProps {
	state: State;
	dispatch: Dispatch<Action>;
}

export function GlassDropzone({ state, dispatch }: GlassDropZoneProps) {
	const theme = useMantineTheme();
	const location = useLocation();

	return (
		<>
			<Progress
				radius={"lg"}
				label={`Uploading... ${state.uploadPercent}% done`}
				value={state.uploadPercent}
				hidden={!state.uploading}
				size="xl"
				style={{ width: "50%", backgroundColor: "#a6d4ff" }}
				animate
			/>
			<Dropzone
				disabled={state.uploading}
				hidden={state.uploading}
				maxFiles={1}
				onDrop={(files) => upload(files[0], location, dispatch)}
				onReject={(files) => console.log("rejected files", files)}
				maxSize={MAX_SIZE_MB * 2 ** 20}
				accept={[
					...PDF_MIME_TYPE,
					...IMAGE_MIME_TYPE,
					MIME_TYPES["mp4"],
					"audio/mpeg",
					"video/mpeg",
					"audio/x-wav",
					"application/javascript",
					"application/x-javascript",
					"text/css",
					"text/plain",
					"text/html",
					"text/javascript",
				]}>
				<Group position="center" spacing="xl" style={{ minWidth: "95vw", minHeight: "95vh", pointerEvents: "none" }}>
					<Dropzone.Accept>
						<IconUpload color={theme.colors[theme.primaryColor][theme.colorScheme === "dark" ? 4 : 6]} size={50} stroke={1.5} />
					</Dropzone.Accept>

					<Dropzone.Reject>
						<IconX color={theme.colors.red[theme.colorScheme === "dark" ? 4 : 6]} size={50} stroke={1.5} />
					</Dropzone.Reject>

					<Dropzone.Idle>
						<IconPhoto size={50} stroke={1.5} />
					</Dropzone.Idle>

					<Stack>
						<Text size="xl" inline>
							Drag images here or click to select a file
						</Text>
						<Text size="sm" color="dimmed" inline mt={7}>
							File should not exceed {MAX_SIZE_MB}mb
						</Text>
					</Stack>
				</Group>
			</Dropzone>
		</>
	);
}
