import { Action, ActionType, GlassDoc } from "../Reducer";
import { API_URL } from "./consts";

/**
 * Checks expiration on current file's pre-signed URL.
 *
 * @param file - Object from state
 * @returns {boolean}
 */
export function checkLinkExpiration(file: GlassDoc) {
	if (!file.presignedUrl) return false;

	const expiresAt = getExpirationFromUrl(file.presignedUrl);
	const curDate = Math.round(new Date().getTime() / 1000);

	return expiresAt <= curDate ? true : false;
}

/**
 * Gets the epoch expiration time from the pre-signed URL.
 *
 * @param url
 * @returns {number}
 */
function getExpirationFromUrl(url: string) {
	const expires = getParameterByName("Expires", url);

	return parseInt(expires || "0");
}

/**
 * Parses URL for specified query parameter.
 *
 * @param name - Query parameter name
 * @param url - URL to parse
 * @returns {string|null}
 */
function getParameterByName(name: string, url: string) {
	if (!url) url = window.location.href;
	name = name.replace(/[[\]]/g, "\\$&");

	const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
		results = regex.exec(url);

	if (!results) return null;
	if (!results[2]) return "";

	return decodeURIComponent(results[2].replace(/\+/g, " "));
}

/**
 * Process a file deletion and updates state.
 *
 * @param file - Object from state
 * @param location - URL pathname to delete
 * @param dispatch - dispatch method to update state
 * @returns {Promise<void>}
 */
export async function deleteFile(file: GlassDoc, location: string, dispatch: React.Dispatch<Action>) {
	try {
		const response = await fetch(API_URL + "/" + file.bucket + "/" + file.fileKey, {
			method: "DELETE",
			headers: {
				"Content-Type": "application/json",
			},
		});

		if (!response.ok) {
			throw new Error(response.statusText);
		}

		dispatch({ type: ActionType.FILE_DELETED });
	} catch (err) {
		if (err instanceof Error) dispatch({ type: ActionType.ERROR, error: err.message });
		else dispatch({ type: ActionType.ERROR, error: "Unknown error" });
	}
}
