import { Flex, LoadingOverlay } from "@mantine/core";
import { useEffect, useReducer, useState } from "react";
import { useLocation } from "react-router-dom";
import { WrongPage } from "./Components/WrongPage";
import { callHeadObject } from "./query/query";
import { ActionType, initialState, reducer } from "./Reducer";
import { FileComponent } from "./Components/FileComponent";
import { GlassDropzone } from "./Components/GlassDropzone";
import { AccessDenied } from "./Components/AccessDenied";

export function GlassDrop() {
	const location = useLocation();
	const [state, dispatch] = useReducer(reducer, initialState);
	const [dataType] = useState(location.pathname.split("/")[1].toLowerCase());

	// Start loading on component render. Refresh page on refreshPage state change.
	useEffect(() => {
		dispatch({
			type: ActionType.START_LOADING,
		});

		callHeadObject(location.pathname, dispatch);
	}, [state.refreshPage, location.pathname]);

	// Redirect to presigned URL if dataType is rawdata and presignedUrl is available.
	useEffect(() => {
		if (dataType === "rawdata" && state.file && state.file.presignedUrl) {
			window.location.href = state.file.presignedUrl;
		}
	}, [state.file, location.pathname, dataType]);

	// Display access denied
	if (state.error === "ACCESS DENIED") return <AccessDenied />;

	// If the file path isn't correct, show the wrong page component.
	if (state.wrongPage || (dataType !== "rawdata" && dataType !== "files")) return <WrongPage />;

	// If the file path is correct but there is no file, show a message.
	if (dataType === "rawdata" && !state.loading && !state.file) return <div>No file here.</div>;

	// If there is an error, show the error message.
	if (state.error) return <div>Error in HTTP Request: {state.error}</div>;

	return (
		<>
			<LoadingOverlay overlayBlur={2} loaderProps={{ variant: "bars" }} visible={state.loading} />
			<Flex hidden={state.loading} mih={"100vh"} justify={"center"} align={"center"}>
				{state.file && <FileComponent file={state.file} dispatch={dispatch} />}
				{!state.file && <GlassDropzone state={state} dispatch={dispatch} />}
			</Flex>
		</>
	);
}
