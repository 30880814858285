export type State = {
	file: GlassDoc | null;
	loading: boolean;
	error: string | null;
	uploaded: boolean;
	uploading: boolean;
	uploadPercent: number;
	refreshPage: boolean;
	wrongPage: boolean;
};

export interface GlassDoc {
	bucket: string;
	fileKey: string;
	fileName: string;
	icon?: string;
	contentType: string;
	presignedUrl?: string;
}

export enum ActionType {
	APIDONE = "apidone",
	FILE_DELETED = "file_deleted",
	UPLOAD_STARTED = "upload_started",
	UPLOAD_PERCENT = "upload_percent",
	UPLOAD_COMPLETE = "upload_complete",
	START_LOADING = "start_loading",
	ERROR = "error",
	WRONG_PAGE = "wrong_page",
}

export type Action =
	| { type: ActionType.APIDONE; file: GlassDoc | null }
	| { type: ActionType.FILE_DELETED }
	| { type: ActionType.UPLOAD_STARTED }
	| { type: ActionType.UPLOAD_PERCENT; percent: number }
	| { type: ActionType.UPLOAD_COMPLETE }
	| { type: ActionType.START_LOADING }
	| { type: ActionType.ERROR; error: string }
	| { type: ActionType.WRONG_PAGE };

export const initialState: State = {
	file: null,
	loading: false,
	error: null,
	uploaded: false,
	uploading: false,
	uploadPercent: 0,
	refreshPage: true,
	wrongPage: false,
};

export function reducer(state: State, action: Action) {
	switch (action.type) {
		case ActionType.APIDONE: {
			return {
				...state,
				file: action.file,
				loading: false,
			};
		}
		case ActionType.FILE_DELETED: {
			return {
				...initialState,
				refreshPage: !state.refreshPage,
			};
		}
		case ActionType.UPLOAD_STARTED: {
			return {
				...state,
				uploaded: false,
				uploading: true,
			};
		}
		case ActionType.UPLOAD_PERCENT: {
			return {
				...state,
				uploadPercent: action.percent,
			};
		}
		case ActionType.UPLOAD_COMPLETE: {
			return {
				...state,
				uploaded: true,
				uploading: false,
				refreshPage: !state.refreshPage,
			};
		}
		case ActionType.START_LOADING: {
			return {
				...state,
				loading: true,
			};
		}
		case ActionType.ERROR: {
			return {
				...state,
				loading: false,
				file: null,
				error: action.error,
			};
		}
		case ActionType.WRONG_PAGE: {
			return {
				...state,
				wrongPage: true,
			};
		}
		default: {
			return state;
		}
	}
}
