import { createStyles, Container, Title, Text, useMantineTheme, Center } from "@mantine/core";

const useStyles = createStyles((theme) => ({
	root: {
		paddingTop: 80,
		paddingBottom: 80,
	},

	inner: {
		position: "relative",
	},

	image: {
		position: "absolute",
		top: 0,
		right: 0,
		left: 0,
		zIndex: 0,
		opacity: 0.75,
	},

	content: {
		paddingTop: 220,
		position: "relative",
		zIndex: 1,

		[theme.fn.smallerThan("sm")]: {
			paddingTop: 120,
		},
	},

	title: {
		fontFamily: `Greycliff CF, ${theme.fontFamily}`,
		textAlign: "center",
		fontWeight: 900,
		fontSize: 38,

		[theme.fn.smallerThan("sm")]: {
			fontSize: 32,
		},
	},

	description: {
		maxWidth: 540,
		margin: "auto",
		marginTop: theme.spacing.xl,
		marginBottom: theme.spacing.xl * 1.5,
	},
}));

// Shows a cool 400 page
export function WrongPage() {
	const { classes } = useStyles();

	return (
		<Container className={classes.root}>
			<div className={classes.inner}>
				<Center>
					<Illustration className={classes.image} />
					<div className={classes.content}>
						<Title className={classes.title}>Nothing to see here</Title>
						<Text color="dimmed" size="lg" align="center" className={classes.description}>
							Page you are trying to open does not exist. Please ensure you are using rawdata or files as the first part of the URL.
						</Text>
					</div>
				</Center>
			</div>
		</Container>
	);
}

// SVG for the 400 page
function Illustration(props: React.ComponentPropsWithoutRef<"svg">) {
	const theme = useMantineTheme();
	return (
		<svg viewBox="0 0 295 105" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				fill={theme.colorScheme === "dark" ? theme.colors.dark[6] : theme.colors.gray[3]}
				d="M 184.058 15.015 L 184.058 90.015 Q 184.058 93.091 182.886 95.838 Q 181.714 98.584 179.663 100.635 Q 177.612 102.686 174.866 103.858 A 14.682 14.682 0 0 1 169.043 105.029 L 116.528 105.029 Q 113.452 105.029 110.706 103.858 Q 107.959 102.686 105.908 100.635 Q 103.857 98.584 102.686 95.838 A 14.682 14.682 0 0 1 101.514 90.015 L 101.514 15.015 Q 101.514 11.939 102.686 9.192 Q 103.857 6.445 105.908 4.431 A 15.933 15.933 0 0 1 110.706 1.209 Q 113.452 0 116.528 0 L 169.043 0 A 14.299 14.299 0 0 1 174.866 1.209 A 15.933 15.933 0 0 1 179.663 4.431 A 14.657 14.657 0 0 1 182.886 9.192 A 14.682 14.682 0 0 1 184.058 15.015 Z M 293.555 15.015 L 293.555 90.015 Q 293.555 93.091 292.383 95.838 Q 291.211 98.584 289.16 100.635 Q 287.109 102.686 284.363 103.858 A 14.682 14.682 0 0 1 278.54 105.029 L 226.025 105.029 Q 222.949 105.029 220.203 103.858 Q 217.456 102.686 215.405 100.635 Q 213.354 98.584 212.183 95.838 A 14.682 14.682 0 0 1 211.011 90.015 L 211.011 15.015 Q 211.011 11.939 212.183 9.192 Q 213.354 6.445 215.405 4.431 A 15.933 15.933 0 0 1 220.203 1.209 Q 222.949 0 226.025 0 L 278.54 0 A 14.299 14.299 0 0 1 284.363 1.209 A 15.933 15.933 0 0 1 289.16 4.431 A 14.657 14.657 0 0 1 292.383 9.192 A 14.682 14.682 0 0 1 293.555 15.015 Z M 0 83.277 L 0 68.848 L 51.782 0 L 69.434 0 L 69.434 68.848 L 83.569 68.848 L 83.569 83.277 L 69.434 83.277 L 69.434 105.029 L 54.419 105.029 L 54.419 83.277 L 0 83.277 Z M 169.043 15.015 L 116.528 15.015 L 116.528 90.015 L 169.043 90.015 L 169.043 15.015 Z M 278.54 15.015 L 226.025 15.015 L 226.025 90.015 L 278.54 90.015 L 278.54 15.015 Z M 16.333 68.848 L 54.419 68.848 L 54.419 18.164 L 16.333 68.848 Z"
				vector-effect="non-scaling-stroke"
			/>
		</svg>
	);
}
